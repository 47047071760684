<template>
	<v-container>
		<v-card flat outlined :style="stickyTop">
			<v-card-title class="black white--text py-1">
				<span>
								{{ apLedger.supplierId }} - {{ apLedger.supplierName }}
							</span>
				<v-spacer />
				<v-btn icon @click.stop="$router.go(-1)"><v-icon color="white">mdi-close-circle-outline</v-icon></v-btn>
			</v-card-title>

			<v-data-table :headers="headers" :items="ledgerEntries" :loading="loading"
				:height="sessionStore.contentHeight - 128 + 'px'" disable-pagination hide-default-footer no-data-text=""
				no-results-text="" fixed-header single-expand show-expand :expanded.sync="expanded"
				v-on:item-expanded="fetchDetail" item-key="geboortenr">
				<template v-slot:[`item.datum`]="{ item }">
					{{ $d(new Date(item.datum), "short") }}
				</template>
				<template v-slot:[`item.faktuur`]="{ item }">
					{{ invoiceType(item.faktuur) }}
				</template>
				<template v-slot:[`item.credit`]="{ item }">
					<span v-if="item.credit">{{ $n(item.credit, "ccy") }}</span>
				</template>
				<template v-slot:[`item.debet`]="{ item }">
					<span v-if="item.debet">{{ $n(item.debet, "ccy") }}</span>
				</template>
				<template v-slot:[`item.schuldsaldo`]="{ item }">
					<span v-if="item.schuldsaldo">{{
						$n(item.schuldsaldo, "ccy")
					}}</span>
				</template>
				<template v-slot:[`item.vervaldatum`]="{ item }">
					{{ $d(new Date(item.vervaldatum), "short") }}
				</template>
				<template v-slot:expanded-item="{ headers }">
					<td :colspan="headers.length">
						<v-container>
							<v-row justify="center">
								<v-col cols="auto">
									<v-data-table class="my-4" :items="ledgerEntriesDetail" :headers="headers2" disable-sort
										disable-pagination hide-default-footer no-data-text="" no-results-text="" dense>
										<template v-slot:[`item.datum`]="{ item }">
											{{
												$d(
													new Date(item.datum),
													"short"
												)
											}}
										</template>
										<template v-slot:[`item.credit`]="{ item }">
											<span v-if="item.credit">{{
												$n(item.credit, "ccy")
											}}</span>
										</template>
										<template v-slot:[`item.debet`]="{ item }">
											<span v-if="item.debet">{{
												$n(item.debet, "ccy")
											}}</span>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-container>
					</td>
				</template>
			</v-data-table>
			<v-divider />
			<v-card-actions v-show="rowIdent">
				<v-spacer />
				<v-btn color="primary" text small :loading="loading" @click="getLedgerEntries()">
					meer tonen
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "GeneralLedger",

	computed: {
		headers() {
			return [
				{ text: "Datum", value: "datum", width: "150px" },
				{ text: "Type", value: "faktuur" },
				{ text: "Ref", value: "ref" },
				{ text: "Debet", value: "debet", align: "end", width: "150px" },
				{
					text: "Credit",
					value: "credit",
					align: "end",
					width: "150px",
				},
				{
					text: "Saldo",
					value: "schuldsaldo",
					align: "end",
					width: "150px",
				},
				{ text: "Vervaldatum", value: "vervaldatum", width: "150px" },
				{ text: "", value: "data-table-expand" },
			];
		},
		headers2() {
			return [
				{ text: "Datum", value: "datum", width: "150px" },
				{ text: "Soort", value: "soort" },
				{ text: "Stuknr", value: "stuknr" },
				{ text: "Debet", value: "debet", align: "end", width: "150px" },
				{
					text: "Credit",
					value: "credit",
					align: "end",
					width: "150px",
				},
				{ text: "Ref.", value: "ref" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
	},
	data() {
		return {
			apLedger: {},
			supplierId: 0,
			supplierName: "",
			expanded: [],
			loading: false,
			ledgerEntries: [],
			ledgerEntriesDetail: [],
			rowIdent: "",
			rowsToBatch: 15,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async getLedgerEntries() {
			const sdoName = "boek/objects/dLhish.r";
			let params = {
				action: "read",
				fiscalYear: Number(this.sessionStore.fiscalYear),
				rowIdent: this.rowIdent,
				nbrOfRows: this.rowsToBatch,
				setOpenQuery: `for each lhish no-lock where lhish.lnr = ${this.apLedger.supplierId}`,
			};

			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			this.ledgerEntries.push(...response.data.data.RowObject);

			let x = response.data.data.nbrOfRows;
			if (x === params.nbrOfRows) {
				this.rowIdent =
					response.data.data.RowObject[x - 1].RowIdent;
			} else {
				this.rowIdent = "";
			}
			this.loading = false;
		},
		async fetchDetail(obj) {
			if (obj.value) {
				const sdoName = "boek/objects/dLhisi.r";
				let params = {
					action: "read",
					fiscalYear: Number(this.sessionStore.fiscalYear),
					nbrOfRows: 999,
					setOpenQuery: `for each lhisi use-index i-sleutel no-lock where lhisi.geboortenr = ${obj.item.geboortenr}`,
				};
				this.loading = true;
				const response = await caas.sdo(sdoName, params);
				this.ledgerEntriesDetail = response.data.data.RowObject;
				this.loading = false;
			} else {
				this.ledgerEntriesDetail = [];
			}
		},
		invoiceType(type) {
			if (type === true) {
				return "FAC";
			}
			if (type === false) {
				return "CRE";
			}
			if (!type) {
				return "VS";
			}
		},
	},
	activated() {
		this.apLedger = this.$router.currentRoute.params.apLedger;
		this.ledgerEntries = [];
		this.rowIdent = "";
		this.ledgerEntriesDetail = [];
		this.getLedgerEntries();
	},
	deactivated() {
		this.ledgerEntries = [];
		this.rowIdent = "";
	},
};
</script>

<style>
</style>